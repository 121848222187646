@import url("https://fonts.googleapis.com/css2?family=Source+Code+Pro&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100&family=Source+Code+Pro&display=swap");

* {
  margin: 0;
}

.App {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  background-size: cover; /* Scale the image to cover the entire element */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-position: center; /* Center the image within the element */
  display: flex;
  overflow-x: hidden;
  overflow-y: scroll;
  flex-direction: column;
}

nav {
  background-color: rgba(0, 0, 0, 0.1);
  min-height: 5vh;
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.2);
  display: flex;
}

.homeReturn {
  color: white;
  font-size: 3vh;
  text-decoration: none;
  margin: 1vh;
  margin-right: 0.5vh;
  font-family: "Roboto", sans-serif;
}

.homeReturn:hover {
  background-color: rgba(0, 0, 0, 0.5);
}

.contactReturn {
  color: white;
  font-size: 3vh;
  text-decoration: none;
  margin: 1vh;
  margin-left: 0.5vh;
  font-family: "Roboto", sans-serif;
}

.contactReturn:hover {
  background-color: rgba(0, 0, 0, 0.5);
}

.Background {
  background-color: #040c36;
  background-position: center;
  position: relative;
  background-size: cover;
  width: 100%;
  min-height: 100vh;
}

.About {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.AlexanderHelium {
  margin: 2.5vh;
  max-height: 80vh;
  max-width: 90%;
  margin-top: 5vh;
  border: 2px solid black;
  border-radius: 5%;
  box-shadow: 0 2vh 4vh rgba(0, 0, 0, 1);
  transition: transform 0.3s ease-in-out;
}

.AlexanderHelium:hover {
  transform: scale(1.05);
}

.TextBox {
  margin: 2.5vh;
  margin-top: 5vh;
  height: 80vh;
  min-width: 40vh;
  max-width: 50vh;
  background-color: rgba(0, 0, 0, 0.2);
  box-shadow: 0 2vh 4vh rgba(0, 0, 0, 1);
  transition: transform 0.3s ease-in-out;
}

.TextBox:hover {
  transform: scale(1.05);
}

p {
  margin: 2.5vh;
  font-size: 2vh;
  color: white;
  font-family: "Source Code Pro", monospace;
}
