@import url("https://fonts.googleapis.com/css2?family=Source+Code+Pro&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100&family=Source+Code+Pro&display=swap");

* {
  margin: 0;
}

.App {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  background-size: cover; /* Scale the image to cover the entire element */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-position: center; /* Center the image within the element */
  display: flex;
  overflow-x: hidden;
  overflow-y: scroll;
  flex-direction: column;
}

nav {
  background-color: rgba(0, 0, 0, 0.1);
  min-height: 5vh;
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.2);
  display: flex;
}
.homeReturn {
  color: white;
  font-size: 3vh;
  text-decoration: none;
  margin: 1vh;
  margin-right: 0.5vh;
  font-family: "Roboto", sans-serif;
}

.homeReturn:hover {
  background-color: rgba(0, 0, 0, 0.5);
}

.contactReturn {
  color: white;
  font-size: 3vh;
  text-decoration: none;
  margin: 1vh;
  margin-left: 0.5vh;
  font-family: "Roboto", sans-serif;
}

.contactReturn:hover {
  background-color: rgba(0, 0, 0, 0.5);
}

.Background {
  background-color: #040c36;
  background-position: center;
  position: relative;
  background-size: cover;
  width: 100%;
  min-height: 100vh;
}

.AboutContact {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.AlexanderRadio2 {
  margin: 5vh;
  border-radius: 5%;
  width: 50vh;
  height: 50vh;
}

@media (max-width: 1000px) {
  .AlexanderRadio2 {
    height: 70vw;
    width: 70vw;
  }
}

.Email1 {
  margin: 2.5vh;
  font-size: 2.5vh;
  color: white;
  font-family: "Source Code Pro", monospace;
}

.Email2 {
  margin: 2.5vh;
  font-size: 2.5vh;
  color: white;
  font-family: "Source Code Pro", monospace;
}

@media (max-width: 1000px) {
  .Email1,
  .Email2 {
    font-size: 5vw;
  }
}
