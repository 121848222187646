@import url("https://fonts.googleapis.com/css2?family=Source+Code+Pro&display=swap");

.App {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  background-size: cover; /* Scale the image to cover the entire element */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-position: center; /* Center the image within the element */
  display: flex;
  overflow-x: hidden;
  overflow-y: scroll;
  flex-direction: column;
}

nav {
  background-color: rgba(0, 0, 0, 0.1);
  min-height: 5vh;
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.2);
  display: flex;
}

.homeReturn {
  color: white;
  font-size: 3vh;
  text-decoration: none;
  margin: 1vh;
  margin-right: 0.5vh;
  font-family: "Roboto", sans-serif;
}

.homeReturn:hover {
  background-color: rgba(0, 0, 0, 0.5);
}

.contactReturn {
  color: white;
  font-size: 3vh;
  text-decoration: none;
  margin: 1vh;
  margin-left: 0.5vh;
  font-family: "Roboto", sans-serif;
}

.contactReturn:hover {
  background-color: rgba(0, 0, 0, 0.5);
}

.Background {
  background-color: #040c36;
  background-position: center;
  position: relative;
  background-size: cover;
  width: 100%;
  min-height: 100vh;
}

.AboutError {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.AlexanderRadio {
  border-radius: 50%;
  height: 60vh;
  width: 60vh;
  animation: rotate 5s infinite linear;
}
.RadioShadow {
  border-radius: 50%;
  height: 60vh;
  width: 60vh;
  margin: 5vh;
  box-shadow: 2vh 2vh 4vh rgba(0, 0, 0, 1);
}

@media (max-width: 1000px) {
  .AlexanderRadio,
  .RadioShadow {
    height: 70vw;
    width: 70vw;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.The404 {
  margin: 2.5vh;
  font-size: 5vh;
  color: white;
  font-family: "Source Code Pro", monospace;
}

.Explain404 {
  margin: 2.5vh;
  font-size: 5vh;
  color: white;
  font-family: "Source Code Pro", monospace;
}

@media (max-width: 1000px) {
  .The404 {
    font-size: 5vw;
  }
}

@media (max-width: 1000px) {
  .Explain404 {
    font-size: 5vw;
  }
}
